<template>  
  <div class="background_basic">          
    <v-card class="ma-2">
      <div>
        <!-- 가장 위쪽에 툴바로 Refresh/엑셀다운로드/신규입력의 기능등을 표시함 -->
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon class="mr-2">mdi-account-check</v-icon>권한
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">refresh</v-icon>초기화
          </v-btn>
          <v-btn color="blue" text @click="ExcelExpert('권한')" class="ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
          </v-btn>
          <v-spacer></v-spacer>

          <!-- 신규입력 다이얼로그 -->
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn color="blue" text v-on="on" :disabled="!ProgramPermissionInfo.okC" @click="ClearDialog()" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">post_add</v-icon>신규등록
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="font-weight-bold">{{ formTitle }}</span> <!-- New Item or Edit Item으로 표시됨 --> 
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="ma-0">
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field v-model="editedItem.cUserRoleId" label="관리코드" :disabled="true" dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field v-model="editedItem.cUserRoleNm" label="권한명" dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.cInfo" label="정보" dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select v-model="editedItem.cSMS"  label="SMS수신여부" :items="zSMSType" item-value="sId" item-text="sNm"></v-select>                             
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.cCreateUserId" label="생성자" :disabled="true" dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.cCreateDateTime" label="생성" :disabled="true" dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.cUpdateUserId" label="수정자" :disabled="true" dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field v-model="editedItem.cUpdateDateTime" label="수정" :disabled="true" dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions> <!-- 카드액션으로 아래쪽에 취소/저장 표시 -->  
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close" :disabled="loading" class="font-weight-bold text-subtitle-1">
                  <v-icon big class="mr-2">cancel</v-icon>취소
                </v-btn>
                <v-btn color="blue darken-2" text @click="save" :disabled="loading" class="ml-5 font-weight-bold text-subtitle-1">
                  <v-icon big class="mr-2">save</v-icon>저장
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </div>
      <v-divider></v-divider>

      <v-row class="ma-0">        
        <v-col cols="12" sm="8" md="8">
          <v-card outlined class="ml-n1 mt-0 pa-3">
            <v-data-table :items-per-page="-1" fixed-header
                v-model="selected"
                :headers="headers"
                :items="Records"
                item-key="cUserRoleId"
                sort-by="cUserRoleId"
                class="mt-n3"
                :single-select="bSingleSelect"
                show-select
                :item-selected="showSelect()"
                @click:row="handleClick"            
                @dblclick:row="handleDoubleClick"
                height="calc(100vh - 225px)"         
              >
              <template v-slot:item.data-table-select="{ isSelected }">
                <v-icon class="ml-0 mr-n10" v-if="isSelected">done</v-icon> 
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">edit</v-icon> 
                <v-icon big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD">delete</v-icon> 
              </template>

              <template v-slot:item.cMapCds="{ item }">
                <v-btn small @click="GetMapItem(item)" :disabled="!ProgramPermissionInfo.okU" color="grey darken-2" dark><v-icon class="mr-1">map</v-icon>맵
                </v-btn>          
              </template>

              <template v-slot:item.cMapNms="{ item }">
                <v-chip label small v-if ="item.cMapNms != ''" color="grey lighten-5">{{item.cMapNms}}</v-chip>
                <v-chip label small v-else color="red lighten-2" dark>선택안됨</v-chip>
              </template>
              <template v-slot:item.cSMS="{ item }">
                <v-chip label small v-if ="item.cSMS == 'Y'" color="blue" dark>전부</v-chip>
                <v-chip label small v-else-if ="item.cSMS == 'EV01'" color="red" dark>비상호출</v-chip>
                <v-chip label small v-else-if ="item.cSMS == 'EV03'" color="black" dark>비인가진입</v-chip>
                <v-chip label small v-else color="grey lighten-5" >안보냄</v-chip>
              </template>
              <template v-slot:no-data>
                <p>No data available.</p> 
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        
        <v-col cols="12" sm="4" md="4">
          <v-card outlined height="calc(100vh - 172px)" class="mt-0 pa-3">          
            <v-card-actions class="mt-n2">
              <v-btn color="blue" text @click="SaveRole" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">save</v-icon>저장
              </v-btn>
              <v-btn color="grey darken-2" text @click="GetRole" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">cancel</v-icon>취소
              </v-btn>
            </v-card-actions>

            <!-- 각 웹페이지의 CRUD값 표시 -->  
            <v-card outlined flat height="calc(100vh - 245px)" class="mb-n5">
              <div style="overflow:scroll; width:100%; height:100%; padding:10px; background-color:#f0f0f0">
                <!-- 메인리스트와 하위리스트를 cGroupGb로 구분하여 색/높이 따로 표시 (Y:상위리스트, Y:하위리스트) --> 
                <v-card :color="getColorGroup(i)" outlined :height="getHeight(i)" flat v-for="(item, i) in levRecords" v-bind:key="i" :class="getClass(i)">

                  <div v-if="levRecords[i].cGroupGb=='Y'"> <!-- 상위리스트 -->
                    <h4 class="ma-2">{{item.cProgramNm}} ({{item.cProgramId}})</h4>
                  </div>

                  <div v-else> <!-- 하위리스트 -->
                    <v-card-actions class="mt-n2 mb-n2">
                      <h5 class="ma-2">{{levRecords[i].cProgramNm}} ({{levRecords[i].cProgramId}})</h5>
                      <v-spacer></v-spacer>
                      <v-btn color="grey darken-2" text @click="SelectProgramAll(i)"> <!-- 클릭시 전체선택(조회/생성/수정/삭제) -->
                        <v-icon big>done</v-icon>ALL
                      </v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>

                    <v-row class="ml-2 mr-2 mt-1"> <!-- CRUD 선택 버튼 -->
                      <v-col cols="12" sm="3" md="3" v-if="levRecords[i].cShowR=='Y'"> 조회
                        <v-switch color="light-green lighten-2" class="mt-n1" v-model="levRecords[i].okR"></v-switch>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" v-if="levRecords[i].cShowC=='Y'"> 생성
                        <v-switch color="light-blue lighten-3" class="mt-n1" v-model="levRecords[i].okC"></v-switch>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" v-if="levRecords[i].cShowU=='Y'"> 수정
                        <v-switch color="amber lighten-2" class="mt-n1" v-model="levRecords[i].okU"></v-switch>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" v-if="levRecords[i].cShowD=='Y'"> 삭제
                        <v-switch color="deep-orange lighten-2" class="mt-n1" v-model="levRecords[i].okD"></v-switch>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>      
    <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>    
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />
    <GetSelMap ref="GetSelMapRef" @onGetMap="onGetMap" />     
  </div>
  
</template>

<script>
import MsgBox from "../components/MsgBox.vue";
import Util from "../Util.js";
import BasicInfo from "../BasicInfo.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import GetSelMap from "@/components/GetSelMap.vue";

export default {
  components: {
    MsgBox,    
    GetSelMap,
  },
  data: () => ({
    bSingleSelect: true,
    loading : false,
    nowSelId: "", 
    iDelIndex: 0,
    dialog: false,
    zSMSType :[{sId:"N", sNm:"안보냄"},{sId:"Y", sNm:"전체"}, {sId:"EV0101", sNm:"비상호출"}, {sId:"EV0102", sNm:"비인가진입"}, {sId:"EV99", sNm:"출입신청"},] ,
    headers: [
      { class: "font-weight-bold subtitle-2", text: "No.", value: "cIdx", align: "center", width: 50},
      // { class: "font-weight-bold subtitle-2", text: "코드 (Role ID)", value: "cUserRoleId", align: "center", width: 130},
      { class: "font-weight-bold subtitle-2", text: "권한명", value: "cUserRoleNm", align: "center", width: 100 },            
      { class: "font-weight-bold subtitle-2", text: "정보", value: "cInfo", align: "center", width: 150 },
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", width: 100, sortable: false},
      { class: "font-weight-bold subtitle-2", text: "SMS", value: "cSMS", align: "center", width: 100 },
      { class: "font-weight-bold subtitle-2", text: "맵선택", value: "cMapCds", align: "center" },    
      { class: "font-weight-bold subtitle-2", text: "맵", value: "cMapNms", align: "left" },                  
      { class: "font-weight-bold subtitle-2", text: "생성자", value: "cCreateUserId", align: "center", width: 100 },
      { class: "font-weight-bold subtitle-2", text: "생성", value: "cCreateDateTime", align: "center", width: 150 },
      { class: "font-weight-bold subtitle-2", text: "수정자", value: "cUpdateUserId", align: "center", width: 100 },
      { class: "font-weight-bold subtitle-2", text: "수정", value: "cUpdateDateTime", align: "center", width: 150 }
      
    ],
    selected: [],
    Records: [],
    editedIndex: -1,
    editedItem: {
      cUserId: "",
      cUserNm: "",
      cInfo: "",
      cToken: "",
      cLev: "",
      cPwd1: "",
      cPwd2: "",
      cSMS : "N",
      cSiteId: "",
      cSiteNm: "",
      cCreateDateTime: "",
      cUpdateDateTime: ""
    },
    deletedItem: {
      cUserId: "",
      cUserNm: "",
      cInfo: "",
      cToken: "",
      cLev: "",
      cSMS : "N",
      cSiteId: "",
      cSiteNm: "",
      cCreateDateTime: "",
      cUpdateDateTime: ""
    },
    defaultItem: {
      cUserId: "",
      cUserNm: "",
      cInfo: "",
      cToken: "",
      cLev: "",
      cSMS : "N",
      cSiteId: "",
      cSiteNm: "",
      cCreateDateTime: "",
      cUpdateDateTime: ""
    },

    levRecords: [
      {
        cProgramId: "LogView",
        cProgramNm: "로그조회",
        cGroupGb: "N",
        cShowC: "Y",
        okC: false,
        cShowR: "Y",
        okR: false,
        cShowU: "Y",
        okU: false,
        cShowD: "Y",
        okD: false
      }
    ]
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "신규 등록" : "항목 수정"; 
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.GetProgramList();
      this.Records = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId
      };
      axios
        .post(BasicInfo.UIL_API + "GetUserRole", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {    
            this.Records = res.data.Result1;
            
            this.nowSelId = "";
            this.selected = [];

            for (let i = 0; i < this.Records.length; i++){
              this.Records[i].cIdx = i+1;              
            }

            for (let i = 0; i < res.data.Result1.length; i++){
              this.Records[i].zMap = [];
              for (let j = 0; j < res.data.Result2.length; j++){
                if (res.data.Result2[j].cUserRoleId == this.Records[i].cUserRoleId) {
                  this.Records[i].cMapCds = res.data.Result2[j].cMapCds;
                  this.Records[i].cMapNms = res.data.Result2[j].cMapNms;
                  this.Records[i].zMap = res.data.Result2[j].cMapCds.split(",");
                  break;
                }
              }
            }


            if (this.Records.length > 0) {
              this.selected.push(this.Records[0]);
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    GetProgramList() {
      this.levRecords = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId
      };
      axios
        .post(BasicInfo.UIL_API + "GetProgramList", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            for (var i = 0; i < res.data.Result.length; i++) {
              var a = {
                cProgramId: res.data.Result[i].cProgramId,
                cProgramNm: res.data.Result[i].cProgramNm,
                cGroupGb: res.data.Result[i].cGroupGb,
                cShowC: res.data.Result[i].cShowC,
                okC: false,
                cShowR: res.data.Result[i].cShowR,
                okR: false,
                cShowU: res.data.Result[i].cShowU,
                okU: false,
                cShowD: res.data.Result[i].cShowD,
                okD: false
              };
              this.levRecords.push(a);
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },
    
    showSelect() {
      if (this.selected.length > 0) {
        if (this.nowSelId != this.selected[0].cUserRoleId) {
          this.nowSelId = this.selected[0].cUserRoleId;
          this.GetRole();
        }
      } else {
        this.nowSelId = "";
      }
    },

    
    GetRole() {
      for (var i = 0; i < this.levRecords.length; i++) {
        this.levRecords[i].okC = false;
        this.levRecords[i].okR = false;
        this.levRecords[i].okU = false;
        this.levRecords[i].okD = false;
      }

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cUserRoleId: this.nowSelId
      };
      axios
        .post(BasicInfo.UIL_API + "GetUserRoleProgramList", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            for (var j = 0; j < res.data.Result.length; j++) {
              for (var i = 0; i < this.levRecords.length; i++) {
                if (
                  this.levRecords[i].cProgramId == res.data.Result[j].cProgramId
                ) {
                  this.levRecords[i].okC = res.data.Result[j].cOkC == "Y";
                  this.levRecords[i].okR = res.data.Result[j].cOkR == "Y";
                  this.levRecords[i].okU = res.data.Result[j].cOkU == "Y";
                  this.levRecords[i].okD = res.data.Result[j].cOkD == "Y";
                  break;
                }
              }
            }
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    
    ClearDialog() {      
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;                      
    },

    
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    
    save() {      
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        cUserRoleId: this.editedItem.cUserRoleId,
        cUserRoleNm: this.editedItem.cUserRoleNm,
        cSMS : this.editedItem.cSMS,
        cInfo: this.editedItem.cInfo
      };
      this.loading = true; 

      axios
        .post(BasicInfo.UIL_API + "SetUserRole", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            if (this.editedIndex > -1) {                                               
              this.editedItem.cUserRoleId = res.data.Result[0].cUserRoleId; 
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);         
            } else {                                                                  
              this.editedItem.cUserRoleId = res.data.Result[0].cUserRoleId;
              this.editedItem.cCreateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              this.Records.push(this.editedItem);                                     
              this.nowSelId = this.editedItem.cUserRoleId;                            
              this.selected = [];
              this.selected.push(this.Records[this.Records.length - 1]);
              this.GetRole();
            }
            this.close();                                                     
            
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");             
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;                                               
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false; 
        });
    },


    editItem(item) {
      this.editedIndex = this.Records.indexOf(item);    
      this.editedItem = Object.assign({}, item);        
      this.dialog = true;
    },

    deleteItem(item) {
      this.iDelIndex = this.Records.indexOf(item);        
      this.deletedItem = Object.assign({}, item);         
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.deletedItem.cUserRoleId +
          "-" +
          this.deletedItem.cUserRoleNm +
          "]"
      );
    },

    onMsgBoxFunc: function(result) {
      if (result === true) {        
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          cUserRoleId: this.deletedItem.cUserRoleId,
          cUserRoleNm: this.deletedItem.cUserRoleNm,
          cInfo: this.deletedItem.cInfo
        };

        axios
          .post(BasicInfo.UIL_API + "DelUserRole", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {
              this.Records.splice(this.iDelIndex, 1);
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },

    
    getClass(idx) {
      if (this.levRecords[idx].cGroupGb == "Y" && idx != 0) {
        return "mb-4 mt-10";
      }
      return "mb-2";
    },
    getColorGroup(idx) {
      if (this.levRecords[idx].cGroupGb == "Y") {
        return "grey lighten-2";
      }
      return "white";
    },
    getHeight(idx) {
      if (this.levRecords[idx].cGroupGb == "Y") {
        return 40;
      }
      return 120;
    },

    
    SaveRole() {
      if (this.nowSelId == "") {
        return;
      }

      var zProgramId = [];
      var zOkC = [];
      var zOkR = [];
      var zOkU = [];
      var zOkD = [];

      this.loading = true;

      for (var i = 0; i < this.levRecords.length; i++) {
        zProgramId.push(this.levRecords[i].cProgramId);
        zOkC.push(Util.sBoolToStr(this.levRecords[i].okC));         //N OR Y의 값으로 넣어줌
        zOkR.push(Util.sBoolToStr(this.levRecords[i].okR));
        zOkU.push(Util.sBoolToStr(this.levRecords[i].okU));
        zOkD.push(Util.sBoolToStr(this.levRecords[i].okD));
      }

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,        
        cUserRoleId: this.nowSelId,
        zProgramId: zProgramId,
        zOkC: zOkC,
        zOkR: zOkR,
        zOkU: zOkU,
        zOkD: zOkD
      };
      axios
        .post(BasicInfo.UIL_API + "SetUserRoleProgramList", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            EventBus.$emit("onShowMsg",false, "Confrim OK.");
            // this.bConfrimDisable = true;
          }
          this.loading = false; 
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);          
          this.loading = false; 
        });        
    },

    // 선택시, selected에 값 넣어줌
    handleClick(value) {      
      this.selected = [value];
    },
    
    // 더블클릭시, update가능하면 선택한 내용을 넣어서 edit 다이얼로그를 띄움
    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       this.editItem(items.item);  
    },

    // All 버튼 클릭시, CRUD값 한꺼번에 변경
    // okC 기준으로 변경 (okC Y시: 모두 N으로 변경, N시: 모두 Y로 변경)
    SelectProgramAll(idx) {
      if (this.levRecords[idx].okC) {
        this.levRecords[idx].okC = false;
        this.levRecords[idx].okR = false;
        this.levRecords[idx].okU = false;
        this.levRecords[idx].okD = false;
      } else {
        this.levRecords[idx].okC = true;
        this.levRecords[idx].okR = true;
        this.levRecords[idx].okU = true;
        this.levRecords[idx].okD = true;
      }
    },

    // 엑셀 다운로드
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx') // name of the file is 'book.xlsx'
    },

    GetMapItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item); 
      this.$refs.GetSelMapRef.show(this.editedItem.zMap); 
    },

    onGetMap(zSelect, cMapNms) {
      this.editedItem.zMap = zSelect;
      this.editedItem.cMapNms = cMapNms;
    
      var posData = {        
        cUserId: this.$store.state.UserInfo.UserId,
        cUserRoleId : this.editedItem.cUserRoleId, 
        zMapGroupId: [],        
        zBuildingId: [],        
        zMapId: [],        
      };

      for (let i in zSelect){
        posData.zMapGroupId.push(zSelect[i].substring(0,2));
        posData.zBuildingId.push(zSelect[i].substring(2,4));
        posData.zMapId.push(zSelect[i].substring(4,6));
      }

      console.log(posData);
      axios
        .post(BasicInfo.UIL_API + "SetRoleMap", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {          
            Object.assign(this.Records[this.editedIndex], this.editedItem);
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },


  }
};
</script>

